<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 头部注释
 * @Date: 2021-01-22 15:09:52
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-25 19:18:00
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/KingCard/kingcard_detail.vue
-->
<template>
  <div class="content">
    <img v-for="(item,index) in list" :key="index" :src="item" alt="">
  </div>
</template>

<script>
import { getKingCardDetails } from '@/services/comeOn'

export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      getKingCardDetails().then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
